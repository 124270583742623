import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap';
import * as dat from 'dat.gui';
import { DoubleSide, PlaneGeometry, TextureLoader } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

/**
 * TODO
 */

// limit zoom level;

/**
 * Global variables
 */
let mouse = { x: -5000, y: -5000 };
let objectHover;
const intersectList = [];

/**
 * Debug GUI
 */
const gui = new dat.GUI({ closed: true });

const debugObject = {
    color: 0xc4c4c4,
    spin: () => { 
        gsap.to(cube.rotation, { y: cube.rotation.y + Math.PI * 2 , duration: 1 })
    }
};

gui
    .addColor(debugObject, 'color')
    .onChange(() => material.color.set(debugObject.color));


// Debug function
// gui
//     .add(debugObject, 'spin');

/**
 * Functions
 */
 const toggleClass = (el, className) => el.classList.toggle(className);

 async function loadModel(name, texture, target, callback = true, scale = 0.3) {
    if (!name || !texture) {
        console.warn(`something went wrong, model name: ${name}, texture ${name}`);
        return;
    }

    const modelTexture = textureLoader.load(`textures/${texture}.jpg`);
    modelTexture.flipY = false;
    modelTexture.encoding = THREE.sRGBEncoding;

    const modelMaterial = new THREE.MeshBasicMaterial({ map: modelTexture });
    
    const model = await new Promise(resolve => {
        gltfLoader.load(`models/${name}.glb`, (model) => {
             model.scene.scale.set(scale, scale, scale);
             model.scene.name = name;
             return resolve(model.scene);
        });
     });

     // Add material to model
     model.traverse((child) => child.material = modelMaterial);
     // Add model to scene
     scene.add(model);
     // If model has selection callback
     if (callback) {
         intersectList.push(model);
         model.callback = () => toggleSidebarContent(target ?? model.name);
     }

     return model.name;
}

const sidebar = document.getElementById('sidebar');

function toggleSidebarContent(target) {
    const activeEl = sidebar.getElementsByClassName('content active')[0];
    const targetEl = document.getElementById(target);

    if (activeEl.id !== target && targetEl) {
        toggleClass(activeEl, 'active');
        toggleClass(targetEl, 'active');
    } else {
        console.warn(`dont toggle target ${target} allready visible!`);
    }
}

function updateCanvasSize() {
    console.log('updateCanvasSize');
    // Update sizes
    sizes.width = canvasContainer.offsetWidth,
    // sizes.width = window.innerWidth,
    sizes.height = canvasContainer.offsetHeight
    // sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}

const elements = document.getElementsByClassName('btn-home');

// classname.addEventListener('click', toggleSidebarContent('home'), false);
Array.from(elements).forEach(function(element) {
    element.addEventListener('click', () => toggleSidebarContent('home'));
});

/**
 * Cursor
 */
const cursor = {
    x: 0,
    y: 0
}

window.addEventListener('mousemove', (event) => 
{
    cursor.x = event.clientX / sizes.width - 0.5;
    cursor.y = -(event.clientY / sizes.height - 0.5);
});


/**
 * Textures
 */
const loadingManager = new THREE.LoadingManager();
const textureLoader = new THREE.TextureLoader(loadingManager);

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('draco/');

// GLTF loader
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Base
 */

// Canvas 
const canvas = document.querySelector('canvas.webgl');
const canvasContainer = document.getElementById('body');

// Sizes
const sizes = {
    width: canvasContainer.offsetWidth,
    height: canvasContainer.offsetHeight
};

window.addEventListener('resize', () => updateCanvasSize());

window.addEventListener('dblclick', () => {
    const fullescreenElement = document.fullscreenElement || document.webkitFullscreenElement;
    // if (!fullescreenElement) {
    //     if (canvas.requestFullscreen) {
    //         canvas.requestFullscreen();
    //     } else if (canvas.webkitRequestFullscreen) {
    //         canvas.webkitRequestFullscreen();
    //     }
    // } else {
    //     if (document.exitFullscreen) {
    //         document.exitFullscreen();
    //     } else if (document.webkitExitFullscreen) {
    //         document.webkitExitFullscreen();
    //     }
    // }
});

// Scene
const scene = new THREE.Scene();


/**
 * Model
*/
loadModel('surface', 'surface', null, false).then((model) => console.log('model loaded', model));

// Inner walls and doors
loadModel('bw-30', 'bw-30').then((model) => console.log('model loaded', model));
loadModel('door-30', 'doors').then((model) => console.log('model loaded', model));
     
loadModel('bw-60', 'bw-60').then((model) => console.log('model loaded', model));
loadModel('door-60', 'doors').then((model) => console.log('model loaded', model));

// Outer walls and floor
loadModel('walls', 'wall').then((model) => console.log('model loaded', model));
loadModel('floor', 'wall').then((model) => console.log('model loaded', model));
loadModel('wall-doors-windows', 'wall-doors-windows', null, false).then((model) => console.log('model loaded', model));

// Support
loadModel('steel-beam', 'support', 'steel').then((model) => console.log('model loaded', model));
loadModel('wooden-beams', 'support', 'wood').then((model) => console.log('model loaded', model));
loadModel('vents', 'support').then((model) => console.log('model loaded', model));


/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.x = -3;
camera.position.y = 3;
camera.position.z = 3;

scene.add(camera);

/**
 * Controls
 */ 
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.minDistance = 2;
controls.maxDistance = 8;
controls.minPolarAngle = Math.PI / 6;
controls.maxPolarAngle = Math.PI / 2.1;

/**
 * AxesHelper
 */
const axesHelper = new THREE.AxesHelper();

// scene.add(axesHelper);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('canvas.webgl')
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor( 0xb2b1ac);
renderer.outputEncoding = THREE.sRGBEncoding;



// Raycaster
const raycaster = new THREE.Raycaster();

canvas.addEventListener('click', onCanvasMouseDown, false);
canvas.addEventListener('mousemove', onCanvasMouseMove, false);

function onCanvasMouseDown(event) {
    event.preventDefault();
    
    mouse.x = ( event.clientX / renderer.domElement.offsetWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / renderer.domElement.offsetHeight ) * 2 + 1;

    raycaster.setFromCamera( mouse, camera );

    const intersects = raycaster.intersectObjects( intersectList, true );

    if ( intersects.length > 0 ) {
        intersects[0].object.parent.callback();
    }

}

function onCanvasMouseMove(event) 
{
    mouse.x = ( event.clientX / renderer.domElement.offsetWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / renderer.domElement.offsetHeight ) * 2 + 1;
}

// Clock
const clock = new THREE.Clock();

// Animations
const tick = () => 
{
    const elapsedTime = clock.getElapsedTime();

    if (scene && scene.children) {
        const mouseVector = new THREE.Vector3( mouse.x, mouse.y, 1 );
        raycaster.setFromCamera( mouse, camera );
        const intersects = raycaster.intersectObjects( intersectList, true );

        if ( intersects.length > 0 )
        {
            if ( intersects[ 0 ].object !== objectHover ) 
            {
                if ( objectHover ) {
                    objectHover.material.color.setHex( objectHover.currentHex );
                }
                // store reference to closest object as current intersection object
                objectHover = intersects[0].object;
                // store color of closest object (for later restoration)
                objectHover.currentHex = objectHover.material.color.getHex();
                // set a new color for closest object
                objectHover.material.color.setHex( 0x6c6c6c );

                document.getElementById('body').style.cursor = 'pointer';
            }
        } else {
            if ( objectHover ) {
                objectHover.material.color.setHex( objectHover.currentHex );
            }
            objectHover = null;
            document.getElementById('body').style.cursor = 'default';
        }
    }
    
    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    window.requestAnimationFrame(tick);
}

tick();


$(function(){
    $("[data-toggle=popover]").popover({
        html : true,
        content: function() {
            const content = $(this).attr("data-popover-content");
            console.log('content', content);
            return $(content).children(".popover-body").html();
        },
        title: function() {
            const title = $(this).attr("data-popover-content");
            return $(title).children(".popover-heading").html();
        }
    });
});